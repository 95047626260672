// src/routes/AppRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router';
import { AllRings } from '../page/all_rings/AllRings';
import { LayOut } from '../LayOut';
import { AllNecklaces } from '../page/all_necklaces/AllNecklaces';
import { AllBracelets } from '../page/all_bracelets/AllBracelets';
import { AllEarrings } from '../page/all_earrings/AllEarrings';
import View from '../page/view/View';
// import Cart from '../page/cart/Cart';
import { Checkout } from '../page/checkout/Checkout';
import NotFound from '../page/NotFound/NotFound';

const AppRoutes = ({ language, handleLanguageChange }) => {
  return (
    <Routes>
      <Route path="/" index element={<LayOut language={language} handleLanguageChange={handleLanguageChange} />} />
      <Route path="/earrings" element={<AllEarrings language={language} />} />
      <Route path="/rings" element={<AllRings language={language} />} />
      <Route path="/necklaces" element={<AllNecklaces language={language} />} />
      <Route path="/allProducts" element={<AllBracelets language={language} />} />
      <Route path='/website_closed' element={<NotFound />}></Route>
      <Route path="/view" element={<div className={`view-container ${language === 'ar' ? 'rtl' : 'ltr'}`}> <View language={language} />        </div>
      } />
      {/* <Route path="/cart" element={<Cart language={language}/>} /> */}
      <Route path='/checkout' element={<Checkout language={language} />} />
    </Routes>
  );
}

export default AppRoutes;
